import { graphql, Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import { Layout } from '../components/Layout';
import Helmet from 'react-helmet';

const BlogPostTemplate = ({ data, pageContext }) => {
  const { frontmatter, body } = data.mdx;
  const { title } = frontmatter;
  const { previous, next } = pageContext;
  const canonicalURL = 'https://phillipmorrow.com'
  return (
    <Layout>
      <Helmet>
        <title>{`phillipmorrow.com - ${title}`}</title>
        <link rel="canonical" href={canonicalURL} />
        <meta property="og:url" content={canonicalURL + frontmatter.cover.publicURL} />
        <meta property="og:description" content={frontmatter.description} />
        <meta property="og:image" content={canonicalURL + frontmatter.cover.publicURL} />
        <meta property="og:image:secure_url" content={canonicalURL + frontmatter.cover.publicURL} />
        <meta property="og:image:alt" content={frontmatter.description} />
        <meta name="medium" content="image" />
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <meta name="viewport" content="width=device-width, user-scalable=no" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@00&display=swap" rel="stylesheet" />
      </Helmet>
      <div className='reader cards-width'>
        <h4>{frontmatter.title} - {frontmatter.date}</h4>
        <hr />
        <div>
          <MDXRenderer>{body}</MDXRenderer>
          <div className="centered">
            {previous === false ? null : (
              <>
                {previous && (
                  <Link to={previous.fields.slug} className="btn btn-success mb-2">
                    <span>{`${previous.frontmatter.title}`}</span>
                  </Link>
                )}
              </>
            )
            }
            {next && previous ? '   ' : ''}
            {
              next === false ? null : (
                <>
                  {next && (
                    <Link to={next.fields.slug} className="btn btn-success mb-2">
                      <span className='alinged-right'>{`${next.frontmatter.title}`}</span>
                    </Link>
                  )}
                </>
              )
            }
          </div>
        </div >
      </div >
    </Layout >
  );
};

export const query = graphql`
query PostBySlug($slug: String!) {
  mdx(fields: { slug: { eq: $slug } }) {
    frontmatter {
      title
      date(formatString: "MMMM DD, YYYY")
      cover {
        publicURL
      }
    }
    body
    excerpt
    fields {
      slug
    }
  }
}
`;

export default BlogPostTemplate;
